// cip panel child routes
const cipPanelRoute = prop => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../views/export-trophy-cip-panel/pages/Dashboard.vue')
  },
  {
    path: 'circular',
    name: prop + '.circular',
    meta: { auth: true },
    component: () => import('../views/export-trophy-cip-panel/pages/circular/List.vue')
  },
  {
    path: 'cip-application',
    name: prop + '.cip_application',
    meta: { auth: true },
    component: () => import('../views/export-trophy-cip-panel/pages/cip-application/List.vue')
  },
  {
    path: 'cip-application-form',
    name: prop + '.cip_application_form',
    meta: { auth: true },
    props: true,
    component: () => import('../views/export-trophy-cip-panel/pages/cip-application/Form.vue')
  },
  {
    path: 'export-trophy-application',
    name: prop + '.export_trophy_application',
    meta: { auth: true },
    component: () => import('../views/export-trophy-cip-panel/pages/export-tophy-application/List.vue')
  },
  {
    path: 'export-trophy-application-form',
    name: prop + '.export_trophy_application_form',
    meta: { auth: true },
    props: true,
    component: () => import('../views/export-trophy-cip-panel/pages/export-tophy-application/Form.vue')
  },
  {
    path: 'gazettes',
    name: prop + '.gazettes',
    meta: { auth: true },
    component: () => import('../views/export-trophy-cip-panel/pages/gazettes/List.vue')
  },
  {
    path: 'cip-award-invitation',
    name: prop + '.cip_award_invitation',
    meta: { auth: true },
    component: () => import('../views/export-trophy-cip-panel/pages/cip-award-invitation/List.vue')
  },
  {
    path: 'export-trophy-award-invitation',
    name: prop + '.export_trophy_award_invitation',
    meta: { auth: true },
    component: () => import('../views/export-trophy-cip-panel/pages/export-trophy-award-invitation/List.vue')
  }
]
// bfti panel child routes
const bftiPanelRoute = prop => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../views/bfti-panel/pages/Dashboard.vue')
  },
  {
    path: 'research-and-other-request-list',
    name: prop + '.research-and-other-request-list',
    meta: { auth: true },
    component: () => import('../views/bfti-panel/pages/research-and-other-request/List')
  },
  {
    path: 'policy-request-entry',
    name: prop + '.policy-request-entry',
    meta: { auth: true },
    component: () => import('../views/bfti-panel/pages/policy-request-entry/List')
  },
  {
    path: 'receive-meeting-notice',
    name: prop + '.receive_meeting_notice',
    meta: { auth: true },
    component: () => import('../views/bfti-panel/pages/receive-meeting-notice/List')
  },
  {
    path: 'survey-list',
    name: prop + '.survey_list',
    meta: { auth: true },
    component: () => import('../views/bfti-panel/pages/survey/List')
  },
  {
    path: 'survey-entry/add/:id?',
    name: prop + '.survey-entry',
    meta: { auth: true },
    component: () => import('../views/bfti-panel/pages/survey/Form')
  },
  {
    path: 'survey-entry/edit',
    name: prop + '.survey-edit',
    meta: { auth: true },
    component: () => import('../views/bfti-panel/pages/survey/EditForm')
  },
  {
    path: 'survey-entry/view/:id?',
    name: prop + '.survey-entry-view',
    meta: { auth: true },
    component: () => import('../views/bfti-panel/pages/survey/View')
  }
]
// dealer panel child routes
const dealerPanelRoute = prop => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../views/dealer-panel/pages/Dashboard.vue')
  },
  {
    path: 'applications',
    name: prop + '.application',
    meta: { auth: true },
    component: () => import('../views/dealer-panel/pages/application/List')
  },
  {
    path: 'applications/:status',
    name: prop + '.application',
    meta: { auth: true },
    component: () => import('../views/dealer-panel/pages/application/List')
  },
  {
    path: 'allocation-letter',
    name: prop + '.allocation-letter',
    meta: { auth: true },
    component: () => import('../views/dealer-panel/pages/allocation-letter/List')
  },
  {
    path: 'allocation-letter/:status',
    name: prop + '.application',
    meta: { auth: true },
    component: () => import('../views/dealer-panel/pages/allocation-letter/List')
  },
  {
    path: 'complain',
    name: prop + '.complain',
    meta: { auth: true },
    component: () => import('../views/dealer-panel/pages/complain/List')
  },
  {
    path: 'faq',
    name: prop + '.faq',
    meta: { auth: true },
    component: () => import('../views/dealer-panel/pages/faq/List')
  }
]
// lrcpn old panel child routes
const lrcpnPanelRoute = prop => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../views/lrcpn-panel/pages/Dashboard.vue')
  },
  {
    path: 'applications',
    name: prop + '.application',
    meta: { auth: true },
    component: () => import('../views/lrcpn-panel/pages/application/List')
  },
  {
    path: 'applications/:status',
    name: prop + '.application',
    meta: { auth: true },
    component: () => import('../views/lrcpn-panel/pages/application/List')
  },
  {
    path: 'application-form',
    name: prop + '.application_form',
    meta: { auth: true },
    component: () => import('../views/lrcpn-panel/pages/application/Form.vue')
  },
  {
    path: 'application-form-edit',
    name: prop + '.application_form_edit',
    meta: { auth: true },
    component: () => import('../views/lrcpn-panel/pages/application/Edit.vue')
  },
  {
    path: 'application-form-resubmit',
    name: prop + '.application_form_edit',
    meta: { auth: true },
    component: () => import('../views/lrcpn-panel/pages/application/Resubmit.vue')
  },
  {
    path: 'application-form-view',
    name: prop + '.application_form_view',
    meta: { auth: true },
    component: () => import('../views/lrcpn-panel/pages/application/View.vue')
  },
  {
    path: 'document-view',
    name: prop + '.document_view',
    meta: { auth: true },
    component: () => import('../views/lrcpn-panel/pages/application/DocumentView.vue')
  }
]
// lrcpn new panel child routes
const lrcpnServicePanelRoute = prop => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/Dashboard.vue')
  },
  // formalin license
  {
    path: 'formalin-import-license-application',
    name: prop + '.formalin_import_license_application',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/formalin-license-application/List')
  },
  {
    path: 'formalin-license-application-form',
    name: prop + '.formalin_license_application_form',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/formalin-license-application/Form')
  },
  {
    path: 'formalin-license-application-form/:id',
    name: prop + '.formalin_license_application_form',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/formalin-license-application/Form')
  },
  // payment related
  {
    path: 'formalin-license-payment/online/success',
    name: prop + '.payment_online_success',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../views/lrcpn-service-panel/pages/formalin-license-application/Payments/Success')
  },
  {
    path: 'formalin-license-payment/online/cancel',
    name: prop + '.payment_online_cancel',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../views/lrcpn-service-panel/pages/formalin-license-application/Payments/Cancel')
  },
  {
    path: 'formalin-license-payment/online/decline',
    name: prop + '.payment_online_decline',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../views/lrcpn-service-panel/pages/formalin-license-application/Payments/Decline')
  },
  // formalin clearance
  {
    path: 'formalin-import-clearance-application',
    name: prop + '.formalin_import_clearance_application',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/formalin-clearance-application/List')
  },
  {
    path: 'formalin-clearance-application-form',
    name: prop + '.formalin_clearance_application_form',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/formalin-clearance-application/Form')
  },
  {
    path: 'formalin-clearance-application-form/:id',
    name: prop + '.formalin_clearance_application_form',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/formalin-clearance-application/Form')
  },
  {
    path: 'acid-import-clearance-application',
    name: prop + '.acid_import_clearance_application',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/acid-import-clearance-application/List')
  },
  {
    path: 'acid-import-clearance-application-form',
    name: prop + '.acid_import_clearance_application_form',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/acid-import-clearance-application/Form')
  },
  {
    path: 'acid-import-clearance-application-form/:id',
    name: prop + '.acid_import_clearance_application_edit',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/acid-import-clearance-application/Form')
  },
  {
    path: 'acid-license-clearance-application',
    name: prop + '.acid_license_clearance_application',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/acid-license-clearance-application/List')
  },
  {
    path: 'acid-license-clearance-application-form',
    name: prop + '.acid_license_clearance_application_form',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/acid-license-clearance-application/Form')
  },
  {
    path: 'acid-license-clearance-application-form/:id',
    name: prop + '.acid_license_clearance_application_edit',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/acid-license-clearance-application/Form')
  },
  {
    path: 'acid-license-clearance-application-details/:id',
    name: prop + '.acid_license_clearance_application_details',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/acid-license-clearance-application/Details')
  },
  {
    path: 'acid-import-clearance-application-details/:id',
    name: prop + '.acid_import_clearance_application_details',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/acid-import-clearance-application/Details')
  },
  {
    path: 'acid-import-clearance-application-certificate/:id',
    name: prop + '.acid_import_clearance_application_certificate',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/acid-import-clearance-application/Certificate')
  },
  {
    path: 'formalin-license-application-details/:id',
    name: prop + '.formalin_license_application_details',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/formalin-license-application/Details')
  },
  {
    path: 'formalin-license-application-certificate/:id',
    name: prop + '.formalin_license_application_certificate',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/formalin-license-application/Certificate')
  },
  {
    path: 'formalin-clearance-application-details/:id',
    name: prop + '.formalin_clearance_application_details',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/formalin-clearance-application/Details')
  },
  {
    path: 'formalin-clearance-application-certificate/:id',
    name: prop + '.formalin_clearance_application_certificate',
    meta: { auth: true },
    component: () => import('../views/lrcpn-service-panel/pages/formalin-clearance-application/Certificate')
  }
]
const associationPanelRoute = prop => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/Dashboard.vue')
  },
  {
    path: 'association-profile-update',
    name: prop + '.association_profile_update',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/ProfileUpdate.vue')
  },
  {
    path: 'association-profile',
    name: prop + '.association_profile',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/ProfileShow.vue')
  },
  {
    path: 'published-notice',
    name: prop + '.published_notice',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/published-notice/List.vue')
  },
  // {
  //   path: 'published-notice/application',
  //   name: prop + '.published_notice',
  //   meta: { auth: true },
  //   component: () => import('../views/association-panel/pages/published-notice/Form.vue')
  // },
  {
    path: 'proposal-submission',
    name: prop + '.proposal_submission',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/proposal-submission/List.vue')
  },
  {
    path: 'program-request',
    name: prop + '.program_request',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/program-request/List.vue')
  },
  {
    path: 'submit-bill',
    name: prop + '.submit_bill',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/submit-bill/List.vue')
  },
  {
    path: 'fund-receive',
    name: prop + '.submit_bill',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/fund-receive/List.vue')
  },
  {
    path: 'policy-advocacy-application',
    name: prop + '.policy_advocacy_application',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/policy-advocacy-application/List.vue')
  },
  {
    path: 'online-product-advertisement',
    name: prop + '.online_product_advertisement',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/online-product-advertisement/List.vue')
  },
  {
    path: 'promotion-of-events',
    name: prop + '.promotion_of_events',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/promotion-of-events/List.vue')
  },
  {
    path: 'public-relationship-management',
    name: prop + '.public_relationship_management',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/public-relationship-management/List.vue')
  },
  {
    path: 'country-branding',
    name: prop + '.country_branding',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/country-branding/List.vue')
  },
  {
    path: 'sales-kits',
    name: prop + '.sales_kits',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/sales-kits/List.vue')
  },
  {
    path: 'policy-advocacy-feedback',
    name: prop + '.policy_advocacy_feedback',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/policy-advocacy-feedback/List.vue')
  },
  {
    path: 'business-directory',
    name: prop + '.business_directory',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/business-directory/List.vue')
  },
  {
    path: 'product-directory',
    name: prop + '.product_directory',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/product-directory/List.vue')
  },
  {
    path: 'training-information',
    name: prop + '.training_information',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/training-information/List.vue')
  },
  {
    path: 'training-summary',
    name: prop + '.training_summary',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/training-summary/List.vue')
  },
  {
    path: 'content-management',
    name: prop + '.content_management',
    meta: { auth: true },
    component: () => import('../views/association-panel/pages/content-management/List.vue')
  }
]
// common profile routes
const commonProfileRoute = prop => [
  {
    path: 'profile',
    name: prop + '.profile',
    meta: { auth: true },
    component: () => import('../views/ProfileShow.vue')
  },
  {
    path: 'profile-update',
    name: prop + '.profile_update',
    meta: { auth: true },
    component: () => import('../views/ProfileUpdate.vue')
  },
  {
    path: 'panel',
    name: prop + '.panel',
    meta: { auth: true },
    component: () => import('../views/Panel.vue')
  }
]
// tea garden panel child routes
const teaGardenPanelRoute = prop => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/Dashboard.vue')
  },
  // for small tea farmer routes
  {
    path: 'tea-farmer-application',
    name: prop + '.tea_farmer_application',
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/tea-farmer-reg/List.vue')
  },
  {
    path: 'tea-farmer-application-form',
    name: prop + '.tea_farmer_application_form',
    meta: { auth: true },
    props: true,
    component: () => import('../views/tea-garden-panel/pages/tea-farmer-reg/Form.vue')
  },
  {
    path: 'tea-farmer-application-renew-form/:parent_id',
    name: prop + '.tea_farmer_application_renew_form',
    meta: { auth: true },
    props: true,
    component: () => import('../views/tea-garden-panel/pages/tea-farmer-reg/RenewForm.vue')
  },
  {
    path: 'small-farmer-certificate/:id',
    name: prop + '.small_farmer_certificate',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../tea-garden-service/btb/ApplicationDetails/SmallTeaFarmer/Certificate.vue')
  },
  {
    path: 'tea-farmer-application-view/:id',
    name: prop + '.tea_farmer_application_view',
    meta: { auth: true },
    props: true,
    component: () => import('../../tea-garden-service/btb/ApplicationDetails/SmallTeaFarmer/Details.vue')
  },
  // for tea garden routes
  {
    path: 'tea-garden-application',
    name: prop + '.tea_garden_application',
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/tea-garden-application/List.vue')
  },
  {
    path: 'tea-garden-application-form',
    name: prop + '.tea_garden_application_form',
    meta: { auth: true },
    props: true,
    component: () => import('../views/tea-garden-panel/pages/tea-garden-application/Form.vue')
  },
  {
    path: 'tea-garden-application/:id',
    name: prop + '.tea_garden_application_show',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../tea-garden-service/btb/ApplicationDetails/TeaGarden/Details.vue')
  },
  {
    path: 'tea-garden-application-certificate/:id',
    name: prop + '.tea_garden_application_certificate',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../tea-garden-service/btb/ApplicationDetails/TeaGarden/Certificate.vue')
  },
  {
    path: 'tea-garden-renew-application-form/:id',
    name: prop + '.tea_garden_renew_application_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../views/tea-garden-panel/pages/tea-garden-application/RenewForm.vue')
  },
  // for tea factory routes
  {
    path: 'tea-factory-application',
    name: prop + '.tea_factory_application',
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/tea-factory-application/List.vue')
  },
  {
    path: 'tea-factory-application-form',
    name: prop + '.tea_factory_application_form',
    meta: { auth: true },
    props: true,
    component: () => import('../views/tea-garden-panel/pages/tea-factory-application/Form.vue')
  },
  {
    path: 'tea-factory-application-renew-form/:parent_id',
    name: prop + '.tea_factory_application_renew_form',
    meta: { auth: true },
    props: true,
    component: () => import('../views/tea-garden-panel/pages/tea-factory-application/RenewForm.vue')
  },
  {
    path: 'tea-factory-certificate/:id',
    name: prop + '.tea_factory_certificate',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../tea-garden-service/btb/ApplicationDetails/TeaFactory/Certificate.vue')
  },
  {
    path: 'tea-factory-application-view/:id',
    name: prop + '.tea_factory_application_view',
    meta: { auth: true },
    props: true,
    component: () => import('../../tea-garden-service/btb/ApplicationDetails/TeaFactory/Details.vue')
  },
  // payments routes
  {
    path: 'payment/:id',
    name: prop + '.payment',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../tea-garden-service/btb/pages/Payment/Payment.vue')
  },
  {
    path: 'service/payment/success',
    name: prop + '.payment_success',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../tea-garden-service/btb/pages/Payment/Success.vue')
  },
  {
    path: 'service/payment/cancel',
    name: prop + '.payment_cancel',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../tea-garden-service/btb/pages/Payment/Cancel.vue')
  },
  {
    path: 'service/payment/decline',
    name: prop + '.payment_decline',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../tea-garden-service/btb/pages/Payment/Decline.vue')
  },
  // payment route end
  // circular list PDU
  {
    path: 'circular-list',
    name: prop + '.circular_list',
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/pdu/CircularList.vue')
  },
  {
    path: 'circular-details/:id',
    name: prop + '.circular_details',
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/pdu/CircularView.vue')
  },
  {
    path: 'circular-details/:id',
    name: prop + '.circular_details',
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/pdu/CircularView.vue')
  },
  {
    path: 'course-application',
    name: prop + '.course_application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('@/modules/tea-garden-service/pdu/pages/course-management/List.vue')
  },
  {
    path: 'course-application-form',
    name: prop + '.course_application_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('@/modules/tea-garden-service/pdu/pages/course-management/Form.vue')
  },
  {
    path: 'course-application-view/:id',
    name: prop + '.course_application_view',
    meta: { auth: true, name: 'Editable' },
    component: () => import('@/modules/tea-garden-service/pdu/pages/course-management/Details.vue')
  },
  {
    path: 'course-application-certificate/:id',
    name: prop + '.course_application_certificate',
    meta: { auth: true, name: 'Editable' },
    component: () => import('@/modules/tea-garden-service/pdu/pages/course-management/Certificate.vue')
  },
  // btb license route start from
  // broker
  {
    path: 'broker-license-application',
    name: prop + '.broker_license_application',
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/brokers-application/List.vue')
  },
  {
    path: 'broker-license-application-form',
    name: prop + '.broker_license_application_form',
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/brokers-application/Form.vue')
  },
  {
    path: 'broker-license-application-renew-form/:parent_id',
    name: prop + '.broker_license_application_renew_form',
    props: true,
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/brokers-application/RenewForm.vue')
  },
  {
    path: 'broker-license-application-view/:id',
    name: prop + '.broker_license_application_view',
    meta: { auth: true },
    props: true,
    component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/BrokerDetails/Details.vue')
  },
  // blender
  {
    path: 'blender-license-application',
    name: prop + '.blender_license_application',
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/blender-application/List.vue')
  },
  {
    path: 'blender-license-application-form',
    name: prop + '.blender_license_application_form',
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/blender-application/Form.vue')
  },
  {
    path: 'blender-license-application-renew-form/:parent_id',
    name: prop + '.blender_license_application_renew_form',
    props: true,
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/blender-application/RenewForm.vue')
  },
  {
    path: 'blender-license-application-view/:id',
    name: prop + '.blender_license_application_view',
    meta: { auth: true },
    props: true,
    component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/BlenderDetails/Details.vue')
  },
  // bidder
  {
    path: 'bidder-license-application',
    name: prop + '.bidder_license_application',
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/bidder-application/List.vue')
  },
  {
    path: 'bidder-license-application-form',
    name: prop + '.bidder_license_application_form',
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/bidder-application/Form.vue')
  },
  {
    path: 'bidder-license-application-renew-form/:parent_id',
    name: prop + '.bidder_license_application_renew_form',
    props: true,
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/bidder-application/RenewForm.vue')
  },
  {
    path: 'bidder-license-application-view/:id',
    name: prop + '.bidder_license_application_view',
    meta: { auth: true },
    props: true,
    component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/BidderDetails/Details.vue')
  },
  // retailer
  {
    path: 'retailer-license-application',
    name: prop + '.retailer_license_application',
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/retailer-application/List.vue')
  },
  {
    path: 'retailer-license-application-form',
    name: prop + '.retailer_license_application_form',
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/retailer-application/Form.vue')
  },
  {
    path: 'retailer-license-application-renew-form/:parent_id',
    name: prop + '.retailer_license_application_renew_form',
    props: true,
    meta: { auth: true },
    component: () => import('../views/tea-garden-panel/pages/retailer-application/RenewForm.vue')
  },
  {
    path: 'retailer-license-application-view/:id',
    name: prop + '.retailer_license_application_view',
    meta: { auth: true },
    props: true,
    component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/RetailerDetails/Details.vue')
  },
  {
    path: 'retailer-license-application-certificate/:id',
    name: prop + '.retailer_license_application_certificate',
    meta: { auth: true },
    props: true,
    component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/RetailerDetails/Certificate.vue')
  },
  {
    path: 'bidder-license-application-certificate/:id',
    name: prop + '.bidder_license_application_certificate',
    meta: { auth: true },
    props: true,
    component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/BidderDetails/Certificate.vue')
  },
  {
    path: 'broker-license-application-certificate/:id',
    name: prop + '.broker_license_application_certificate',
    meta: { auth: true },
    props: true,
    component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/BrokerDetails/Certificate.vue')
  },
  {
    path: 'blender-license-application-certificate/:id',
    name: prop + '.blender_license_application_certificate',
    meta: { auth: true },
    props: true,
    component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/BlenderDetails/Certificate.vue')
  },
    // wearhouse
    {
      path: 'wearhouse-license-application',
      name: prop + '.wearhouse_license_application',
      meta: { auth: true },
      component: () => import('../views/tea-garden-panel/pages/wearhouse-application/List.vue')
    },
    {
      path: 'wearhouse-license-application-form',
      name: prop + '.wearhouse_license_application_form',
      meta: { auth: true },
      component: () => import('../views/tea-garden-panel/pages/wearhouse-application/Form.vue')
    },
    {
      path: 'wearhouse-license-application-renew-form/:parent_id',
      name: prop + '.wearhouse_license_application_renew_form',
      props: true,
      meta: { auth: true },
      component: () => import('../views/tea-garden-panel/pages/wearhouse-application/RenewForm.vue')
    },
    {
      path: 'wearhouse-license-application-view/:id',
      name: prop + '.wearhouse_license_application_view',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/WearhouseDetails/Details.vue')
    },
    {
      path: 'wearhouse-license-application-certificate/:id',
      name: prop + '.wearhouse_license_application_certificate',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/WearhouseDetails/Certificate.vue')
    },
      // export
    {
      path: 'export-license-application',
      name: prop + '.export_license_application',
      meta: { auth: true },
      component: () => import('../views/tea-garden-panel/pages/export-application/List.vue')
    },
    {
      path: 'export-license-application-form',
      name: prop + '.export_license_application_form',
      meta: { auth: true },
      component: () => import('../views/tea-garden-panel/pages/export-application/Form.vue')
    },
    {
      path: 'export-license-application-view/:id',
      name: prop + '.export_license_application_view',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/ExportDetails/Details.vue')
    },
    {
      path: 'export-license-application-certificate/:id',
      name: prop + '.export_license_application_certificate',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/ExportDetails/Certificate.vue')
    },
  // btb license route end
  // payment routes
  {
    path: 'course/payment/:id',
    name: prop + '.course_payment',
    meta: { auth: true, name: 'Editable' },
    component: () => import('@/modules/tea-garden-service/pdu/pages/course-management/Payment/Form.vue')
  },
  {
    path: 'course/test/payment/success',
    name: prop + '.course_payment_success',
    meta: { auth: true, name: 'Editable' },
    component: () => import('@/modules/tea-garden-service/pdu/pages/course-management/Payment/Success.vue')
  },
  {
    path: 'course/test/payment/cancel',
    name: prop + '.course_payment_cancel',
    meta: { auth: true, name: 'Editable' },
    component: () => import('@/modules/tea-garden-service/pdu/pages/course-management/Payment/Cancel.vue')
  },
  {
    path: 'course/test/payment/decline',
    name: prop + '.course_payment_decline',
    meta: { auth: true, name: 'Editable' },
    component: () => import('@/modules/tea-garden-service/pdu/pages/course-management/Payment/Decline.vue')
  },
  // received notification
  {
    path: 'received-notifications',
    name: prop + '.received_notifications',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../tea-garden-service/configuration/pages/notification/List.vue')
  }
]
const tradeFairRoute = prop => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/itf/Dashboard.vue')
  },
  {
    path: 'fair-participation-circular',
    name: prop + '.fair_participation_circular',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/itf/fair-participation-circular/List')
  },
  {
    path: 'exhibition-circular-list',
    name: prop + '.exhibition_circular_list',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/itf/exhibit-circular-list/List')
  },
  {
    path: 'exhibit-information-form',
    name: prop + '.exhibition_information_form',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/itf/exhibit-information/Form')
  },
  {
    path: 'exhibit-information',
    name: prop + '.exhibit_information',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/itf/exhibit-information/List')
  },
  {
    path: 'fair-participation',
    name: prop + '.fair_participation',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/itf/fair-participation/List')
  },
  {
    path: 'fair-participation/:status',
    name: prop + '.fair_participation',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/itf/fair-participation/List')
  },
  {
    path: 'fair-participation-form',
    name: prop + '.fair_participation_form',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/itf/fair-participation/Form')
  },
  {
    path: 'visa-processing-info-list',
    name: prop + '.visa_processing_info_list',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/itf/visa-processing-info/List')
  },
  {
    path: 'fair-evaluation',
    name: prop + '.fair_evaluation',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/itf/fair-evaluation/List')
  }
]

const dhakaTradeFairRoute = prop => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/ditf/Dashboard.vue')
  },
  {
    path: 'circular-list',
    name: prop + '.circular_list',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/ditf/participation-circular/List')
  },
  {
    path: 'fair-application-form',
    name: prop + '.fair_application_form',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/ditf/fair-application/Form')
  },
  {
    path: 'fair-application',
    name: prop + '.fair_application',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/ditf/fair-application/List')
  },
  {
    path: 'fair-application/:status?',
    name: prop + '.fair_application',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/ditf/fair-application/List')
  },
  {
    path: 'category-wise-stall-payment',
    name: prop + '.category_wise_stall_payment',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/ditf/category-wise-stall-payment/List')
  },
  {
    path: 'category-wise-stall-payment/:status?',
    name: prop + '.category_wise_stall_payment',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/ditf/category-wise-stall-payment/List')
  },
  {
    path: 'refund-info',
    name: prop + '.refund_info',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/ditf/refund-info/List')
  }
]

const localFairRoute = prop => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/local-fair/Dashboard.vue')
  },
  {
    path: 'local-fair-application',
    name: prop + '.local_fair_application',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/local-fair/fair-application/List.vue')
  },
  {
    path: 'fair-application-form',
    name: prop + '.fair_application_form',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/local-fair/fair-application/Form.vue')
  },
  {
    path: 'fair-application-form/:id',
    name: prop + '.fair_application_form',
    meta: { auth: true },
    component: () => import('../views/trade-fair-panel/pages/local-fair/fair-application/Form.vue')
  },
  // payments routes
  {
    path: 'payment/:id',
    name: prop + '.payment',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../views/trade-fair-panel/pages/local-fair/Payment/Payment.vue')
  }
]

const tradeAndTariffRoute = prop => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../views/trade-and-tariff-panel/pages/Dashboard.vue')
  },
  {
    path: 'application',
    name: prop + '.application',
    meta: { auth: true },
    component: () => import('../views/trade-and-tariff-panel/pages/application/List')
  },
  {
    path: 'application-form',
    name: prop + '.application_form',
    meta: { auth: true },
    component: () => import('../views/trade-and-tariff-panel/pages/application/Form')
  },
  {
    path: 'application-form/:id',
    name: prop + '.application_form',
    meta: { auth: true },
    component: () => import('../views/trade-and-tariff-panel/pages/application/Form')
  },
  {
    path: 'search-hs-code',
    name: prop + '.search_hs_code',
    meta: { auth: true },
    component: () => import('../views/trade-and-tariff-panel/pages/search-hs-code/List')
  },
  {
    path: 'country-wise-tariff-data',
    name: prop + '.country_wise_tariff_data',
    meta: { auth: true },
    component: () => import('../views/trade-and-tariff-panel/pages/country-wise-tariff-data/List')
  },
  {
    path: 'request-for-appointment',
    name: prop + '.request_for_appointment',
    meta: { auth: true },
    component: () => import('../views/trade-and-tariff-panel/pages/request-for-appointment/List')
  },
  {
    path: 'appointment-history',
    name: prop + '.appointment_history',
    meta: { auth: true },
    component: () => import('../views/trade-and-tariff-panel/pages/appointment-history/List')
  },
  {
    path: 'rating-and-feedback',
    name: prop + '.rating_and_feedback',
    meta: { auth: true },
    component: () => import('../views/trade-and-tariff-panel/pages/rating-and-feedback/Form')
  },
  {
    path: 'country-profile',
    name: prop + '.country_profile',
    meta: { auth: true },
    component: () => import('../views/trade-and-tariff-panel/pages/country-profile/List')
  }
]
// tea garden panel child routes
const trainingServiceRoute = prop => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../views/training/pages/Dashboard.vue')
  },
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () =>
        import('../views/training/pages/Dashboard.vue')
  },
  {
    path: 'profile',
    name: prop + '.profile',
    meta: { auth: true },
    component: () =>
        import('../views/training/pages/profile/Form.vue')
  },
  {
    path: 'professional-profile',
    name: prop + '.professional_profile',
    meta: { auth: true },
    component: () =>
        import('../views/training/pages/professional-profile/Details.vue')
  },
  {
    path: 'circular-list',
    name: prop + '.circular_list',
    meta: { auth: true },
    component: () => import('../views/training/pages/circular-list/List.vue')
  },
  {
    path: 'circular-list/:status',
    name: prop + '.circular_list.status',
    meta: { auth: true },
    component: () => import('../views/training/pages/circular-list/List.vue')
  },
  {
    path: 'trainee-evaluation',
    name: prop + '.trainee_evaluation',
    meta: { auth: true },
    component: () => import('../views/training/pages/trainee-evaluation/List.vue')
  },
  {
    path: 'trainee-evaluation-form',
    name: prop + '.trainee_evaluation_form',
    meta: { auth: true },
    component: () => import('../views/training/pages/trainee-evaluation/Form.vue')
  },
  {
    path: 'trainee-evaluation-details',
    name: prop + '.trainee_evaluation_details',
    meta: { auth: true },
    component: () => import('../views/training/pages/trainee-evaluation/Details.vue')
  },
  {
    path: 'certificate-list',
    name: prop + '.certificate_list',
    meta: { auth: true },
    component: () => import('../views/training/pages/certificate-list/List.vue')
  },
  {
    path: 'certificate-generate-details-two',
    name: prop + 'certificate_generate_details_two',
    meta: { auth: true },
    component: () => import('../views/training/pages/certificate-list/DetailsTwo.vue')
  },
  {
    path: 'complains-list',
    name: prop + '.complains_list',
    meta: { auth: true },
    component: () => import('../views/training/pages/complains/List.vue')
  },
  {
    path: 'faq-management',
    name: prop + '.faq_management',
    meta: { auth: true },
    component: () => import('../views/training/pages/faq-management/List.vue')
  },
  {
    path: 'request-document',
    name: prop + '.request_document',
    meta: { auth: true },
    component: () => import('../views/training/pages/request-document/List.vue')
  },
  {
    path: 'bill-receive',
    name: prop + '.bill_receive',
    meta: { auth: true },
    component: () => import('../views/training/pages/payment/List.vue')
  },
   {
    path: 'booking-status',
    name: prop + '.booking_status',
    meta: { auth: true },
    component: () => import('../views/training/pages/booking-status/List.vue')
  },
   {
    path: 'routine',
    name: prop + '.training_schedule',
    meta: { auth: true },
    component: () => import('../views/training/pages/training-schedule/List.vue')
  },
  {
    path: 'evaluation',
    name: prop + '.evaluation',
    meta: { auth: true },
    component: () => import('../views/training/pages/evaluation/List.vue')
  },
  {
    path: 'course-evaluation',
    name: prop + '.course_evaluation',
    meta: { auth: true },
    component: () => import('../views/training/pages/course-evaluation/List.vue')
  },
  {
    path: 'course-evaluation-form',
    name: prop + '.course_evaluation_form',
    meta: { auth: true },
    component: () => import('../views/training/pages/course-evaluation/Form.vue')
  },
  {
    path: 'trainer-evaluation',
    name: prop + '.trainer_evaluation',
    meta: { auth: true },
    component: () => import('../views/training/pages/trainer-evaluation/List.vue')
  },
  {
    path: 'trainer-evaluation-form',
    name: prop + 'trainer_evaluation_form',
    meta: { auth: true },
    component: () => import('../views/training/pages/trainer-evaluation/Form.vue')
  },
  {
    path: 'id-card',
    name: prop + '.id_card',
    meta: { auth: true },
    component: () => import('../views/training/pages/id-card/List.vue')
  },
  {
    path: 'received-notifications',
    name: prop + '.received_notifications',
    meta: { auth: true },
    component: () => import('../views/training/pages/received-notifications/List.vue')
  },
  {
    path: '/secretary-dashboard',
    name: prop + '.secretary-dashboard',
    meta: { auth: true },
    component: () => import('../views/SecretaryDashboard.vue')
  }
]
const routes = [
  {
    path: '/export-trophy-cip-panel',
    name: 'export_trophy_cip_panel',
    component: () => import('@/layouts/ExternalUser/CipPanelLayout.vue'),
    meta: { auth: true },
    children: cipPanelRoute('export_trophy_cip_panel')
  },
  {
    path: '/external-user',
    name: 'external_user',
    component: () => import('@/layouts/ExternalUser/CommonLayout.vue'),
    meta: { auth: true },
    children: commonProfileRoute('external_user')
  },
  {
    path: '/bfti-panel',
    name: 'bfti_panel',
    component: () => import('@/layouts/ExternalUser/BftiPanelLayout.vue'),
    meta: { auth: true },
    children: bftiPanelRoute('bfti_panel')
  },
  {
    path: '/dealer-panel',
    name: 'dealer_panel',
    component: () => import('@/layouts/ExternalUser/DealerPanelLayout.vue'),
    meta: { auth: true },
    children: dealerPanelRoute('dealer_panel')
  },
  {
    path: '/lrcpn-panel',
    name: 'lrcpn_panel',
    component: () => import('@/layouts/ExternalUser/LrcpnPanelLayout.vue'),
    meta: { auth: true },
    children: lrcpnPanelRoute('lrcpn_panel')
  },
  {
    path: '/lrcpn-service-panel',
    name: 'lrcpn_service_panel',
    component: () => import('@/layouts/ExternalUser/LrcpnServicePanelLayout.vue'),
    meta: { auth: true },
    children: lrcpnServicePanelRoute('lrcpn_service_panel')
  },
  {
    path: '/association-panel',
    name: 'association_panel',
    component: () => import('@/layouts/ExternalUser/AssociationPanelLayout.vue'),
    meta: { auth: true },
    children: associationPanelRoute('association_panel')
  },
  {
    path: '/training-panel',
    name: 'training_panel',
    component: () => import('@/layouts/ExternalUser/TrainingPanelLayout.vue'),
    meta: { auth: true },
    children: trainingServiceRoute('training_panel')
  },
  {
    path: '/tea-garden-panel',
    name: 'tea_garden_panel',
    component: () => import('@/layouts/ExternalUser/TeaGardenPanelLayout.vue'),
    meta: { auth: true },
    children: teaGardenPanelRoute('tea_garden_panel')
  },
  {
    path: '/trade-fair-panel',
    name: 'trade_fair_panel',
    component: () => import('@/layouts/ExternalUser/ITFPanelLayout.vue'),
    meta: { auth: true },
    children: tradeFairRoute('trade_fair_panel')
  },
  {
    path: '/dhaka-trade-fair-panel',
    name: 'trade_fair_panel',
    component: () => import('@/layouts/ExternalUser/DITFPanelLayout.vue'),
    meta: { auth: true },
    children: dhakaTradeFairRoute('trade_fair_panel')
  },
  {
    path: '/local-fair-panel',
    name: 'local_fair_panel',
    component: () => import('@/layouts/ExternalUser/LocalFairLayout.vue'),
    meta: { auth: true },
    children: localFairRoute('local_fair_panel')
  },
  {
    path: '/trade-and-tariff-panel',
    name: 'trade_and_tariff_panel',
    component: () => import('@/layouts/ExternalUser/TradeAndTariffPanelLayout.vue'),
    meta: { auth: true },
    children: tradeAndTariffRoute('trade_and_tariff_panel')
  }
]

export default routes
